import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import SEO from '../../components/Seo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO description="Using TypeScript with React class components." title="Class Components" keywords={['class components']} mdxType="SEO" />
    <h1 {...{
      "id": "class-components"
    }}>{`Class Components`}</h1>
    <p><inlineCode parentName="p">{`React.Component`}</inlineCode>{` is used to describe a class component. `}<inlineCode parentName="p">{`React.Component`}</inlineCode>{` is a generic type that takes two `}<strong parentName="p"><em parentName="strong">{`optional`}</em></strong>{` type variables, `}<inlineCode parentName="p">{`PropType`}</inlineCode>{` and `}<inlineCode parentName="p">{`StateType`}</inlineCode>{`.`}</p>
    <p>{`The following example is from the `}<a parentName="p" {...{
        "href": "https://github.com/typescript-cheatsheets/react-typescript-cheatsheet"
      }}>{`react-typescript-cheatsheet`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`type MyProps = {
  // using \`interface\` is also ok
  message: string;
};
type MyState = {
  count: number; // like this
};
class App extends React.Component<MyProps, MyState> {
  state: MyState = {
    // optional second annotation for better type inference
    count: 0,
  };
  render() {
    return (
      <div>
        {this.props.message} {this.state.count}
      </div>
    );
  }
}
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=9lUN3sqAjQQ"
      }}>{`This video`}</a>{` explains another example of typing a class component.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      